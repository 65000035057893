import React, { useState } from 'react'
import hero_image from '../.././../images/Home/hero_image.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Link } from 'react-router-dom'
import { xxlText, xxsText } from '../../../style/text'
const Banner = () => {
  const [isBannerLoaded, setIsBannerLoaded] = useState(false)

  return (
    <div className='min-h-[500px] sm:h-[700px]  md:h-[950px] relative flex justify-center items-center overflow-hidden '>
      <div className='absolute mt-[-180px] min-w-[500px]'>
        <div className={`z-10 absolute ${isBannerLoaded ? 'bg-[#869999]' : ''} bg-opacity-20 w-full h-full`}></div>
        <LazyLoadImage effect='blur' src={hero_image} className='min-w-[500px]' onLoad={()=>setIsBannerLoaded(true)} />
      </div>
      <div className='flex flex-col z-10 items-center justify-center gap-6 w-full'>
        <h1 className={`font-bold text-white text-center px-5 ${xxlText}`}>
          Transforming Outdoor Spaces
        </h1>
        <p className={`text-white ${xxsText} max-w-[550px] text-center px-2`}>
          Elevate your property’s appeal with our professional landscaping
          services, tailored to your style, needs, and budget. Discover more
          today.
        </p>
        <div className='flex gap-5 flex-col xs:flex-row'>
          <Link
            to={'/gallery'}
            className='border-[#B6D3C5] border-[1px] flex items-center'
          >
            <h1 className='text-white font-bold p-2 px-5'>VIEW OUR GALLERY</h1>
          </Link>
          <a
            href={'#contact'}
            onClick={e => {
              e.preventDefault()
              const contactSection = document.querySelector('#contact')
              if (contactSection) {
                contactSection.scrollIntoView({
                  behavior: 'smooth',
                })
              }
            }}
            className='bg-[#059669] flex items-center justify-center'
          >
            <h1 className='text-white font-bold p-4 px-5'>CONTACT US</h1>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Banner
