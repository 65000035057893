import React from 'react'

const SectionContainer = ({
  className,
  id,
  children,
}: {
  className?: string,
  id?: string,
  children?: React.ReactNode
}) => {
  //FIXME - Add changes to this so it's mobile friendly
  return <div className={`py-[100px] px-[25px] lg:px-[100px] 2xl:px-[300px] ${className}`} id={id}>{children}</div>
}

export default SectionContainer
