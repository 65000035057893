import React from 'react'
import SectionContainer from '../utils/SectionContainer'
import imageSrc from '../../../images/Home/TestimonialSection/person_image.png'
import { Link } from 'react-router-dom'
import { mdText, smText, xsText } from '../../../style/text'
import SlideUpAnimation from '../../../components/common/SlideUpAnimation'

const Testimonial = () => {
  return (
    <SlideUpAnimation backgroundColor='#FFFFFF'>
      <SectionContainer className='flex justify-center w-full bg-emerald-50'>
        <div className='w-[750px] flex flex-col gap-8 justify-center items-center'>
          <img src={imageSrc} alt='' />
          <h1 className={`text-green-800 ${mdText} font-bold text-center`}>
            “Great work and so personable. Very understanding to my high demand of
            quality. And they Nailed it!!! Recommending everyone I know to these
            guys”
          </h1>
          <div className='flex flex-col gap-3 items-center'>
            <a
            className={`text-green-800 ${smText} font-bold`}
              href={
                'https://www.google.com/maps/contrib/102506620602838937587/reviews/@47.3747272,-122.2389836,16z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US&entry=ttu'
              }
              target='_blank'
              rel='noopener noreferrer'
            >
              Tomy Richard
            </a>
            <p className={`text-green-800 ${xsText}`}>Satisfied customer</p>
          </div>
        </div>
      </SectionContainer>
    </SlideUpAnimation>
  )
}

export default Testimonial
