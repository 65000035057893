import React from 'react'
import { service_section_data } from '../utils/service-section-data'
import { Link } from 'react-router-dom'
import {
  ArrowRightIcon
} from '@heroicons/react/24/solid'
import SectionContainer from '../utils/SectionContainer'
import { listText, smText, xlText, xsText } from '../../../style/text'
import SlideUpAnimation from '../../../components/common/SlideUpAnimation'

const ServiceSection = () => {
  return (
    <SectionContainer className='bg-emerald-50'>
      <SlideUpAnimation className={'flex flex-col justify-center gap-20'}>
        <div className='flex flex-col md:flex-row items-start gap-8 md:gap-16'>
          <h1 className={`font-bold text-green-800 ${xlText} w-content text-start flex flex-col`}>
            <span className='whitespace-normal sm:whitespace-nowrap'>Our Comprehensive</span>
            <span className='whitespace-nowrap'>Landscaping</span>
            <span className='whitespace-nowrap'>Services</span>
          </h1>
          <p className={`${xsText} w-full border-s-4 border-green-500 mt-5 pl-3`}>
            Discover a world of possibilities with our comprehensive landscaping
            services. From lush lawn care to elegant hardscape designs, we
            specialize in creating outdoor environments that captivate and
            inspire. Let us bring your vision to life and turn your property into
            a stunning masterpiece that reflects your unique style and
            personality.
          </p>
        </div>
        <div className='flex gap-10 flex-col md:flex-row md:max-w-full max-w-[500px] m-auto'>
          {service_section_data.map(({ title, imageSrc, list }) => (
            <div className='flex flex-col w-full gap-5' key={title}>
              <img
                
                src={imageSrc}
                className='object-center object-cover h-[300px] w-full relative overflow-clip'
              />
              <h1 className={`text-green-800 ${smText} font-bold`}>{title}</h1>
              <ul className="list-disc ml-4 flex flex-col gap-2">
                {list.map(bulletinItems => (
                  <li className={`text-green-800 ${listText}`} key={bulletinItems}>{bulletinItems}</li>
                ))}
              </ul>
              <Link to={'/service'} className='flex items-center gap-1 text-green-600'>
                  View <ArrowRightIcon width={18}/>
              </Link>
            </div>
          ))}
        </div>
      </SlideUpAnimation>
    </SectionContainer>
  )
}

export default ServiceSection
